import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '~/services/history';
import { ISearchState, ISearchPhrasePayload } from './interfaces';

const mapSlice = createSlice({
	name: 'search',
	initialState: initialState.search as ISearchState,
	reducers: {
		setPhrase: (state: ISearchState, action: PayloadAction<ISearchPhrasePayload>) => {
			const { phrase } = action.payload;

			state.phrase = phrase;
		},
	},
});

// dle "duck" metodiky jsou akce pojmenovane exporty a reducer default export
export const { setPhrase } = mapSlice.actions;
export default mapSlice.reducer;

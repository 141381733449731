/**
 * @constant
 * Sidebar width in CSS pixels.
 */
export const SIDEBAR_WIDTH = 320;

/**
 * @constant
 * Ok HTTP response status.
 */
export const HTTP_STATUS_OK = 200;

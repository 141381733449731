import React from 'react';
import { useSelector } from 'react-redux';

import './styles.less';

export default function DevState() {
	const state = useSelector(storeState => storeState);

	return (
		<pre className="devState">
			{JSON.stringify(state, null, '\t')}
		</pre>
	);
}

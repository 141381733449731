import { call } from '~/helpers/net/rpc';
import { ICoords } from '~/features/map/interfaces';
import { HTTP_STATUS_OK } from '~/constants';

// url poiagregatoru
const ENDPOINT = 'https://pro.mapy.cz/poiagg';

// pokud nenajdeme ikonu pro POI, pouzijeme neutralni
const DEFAULT_ICON = 290;

// TODO: tohle se asi presune do neceho generickeho, bude vic FRPC endpointu
export interface IFRPCResponse {
	status: number;
	statusMessage: string;
}

export interface IPOIGalleryEntry {
	approval: boolean;
	authorId: number;
	authorName: string;
	best: boolean;
	createDate: string;
	description: string;
	dirty: boolean;
	id: number;
	mark: any;
	markCamera: any;
	name: string;
	origin: string;
	ratios: any;
	rotation: number;
	takeDate: string | null;
	type: 'image';
	urls: {
		'16x9'?: string;
		'1x1'?: string;
		default: string;
	}
}

export interface IPOI {
	source: string;
	id: number | string;
	desc?: {
		text: string;
	};
	description?: string;
	extend?: object;
	gallery: Array<{
		media: Array<IPOIGalleryEntry>;
		sequence: any;
	}>;
	geom?: {
		data: Array<string>;
		style: {
			opacity?: string;
			width?: string;
			color: string;
			outlineOpacity?: string;
			outlineWidth?: string;
			outlineColor?: string;
			offsetWidth?: string;
			endCap?: 'round' | 'square' | 'butt',
		},
		type: 'linestring' | 'multilinestring' | 'polygon' | 'multipolygon';
	},
	mark: {
		lat: number;
		lon: number;
	},
	title: string;
}

export interface IDetailResponse extends IFRPCResponse {
	poi: IPOI;
}

export interface IPOIIcon {
	height: number;
	id: number;
	width: number;
}

export interface IPOIType {
	// eslint-disable-next-line camelcase
	icon_id: number;
	id: number;
}

export interface IPOIIconResponse extends IFRPCResponse {
	poiicon: Array<IPOIIcon>;
	poitype: Array<IPOIType>;
}

export interface ILookupPOI extends IPOI {
	geomInLookup: boolean;
	icon?: {
		[key: number]: string;
	}
	paid?: number;
	title: string;
	typeId: number;
	zoom: number;
	zoomMax: number;
}

export interface ILookupOptions {
	zoom?: number;
	mapsetId?: number;
	pixelSize?: number;
	lang?: Array<string>;
}

export interface ILookupResponse extends IFRPCResponse {
	poi: Array<ILookupPOI>;
}

export function getDetail(source: string, id: string | number, options: any): Promise<IDetailResponse> {
	if (source === 'coor' && typeof id === 'string') {
		const coords = id.split(',');
		const [lon, lat] = coords;

		return Promise.resolve({
			status: HTTP_STATUS_OK,
			statusMessage: 'Ok',
			poi: {
				source,
				id,
				mark: {
					lon: parseFloat(lon),
					lat: parseFloat(lat),
				},
				// TODO: lokalizace
				title: 'Souřadnice na mapě',
				gallery: [],
			},
		} as IDetailResponse);
	}

	return call('detail', [source, id, options], {
		url: ENDPOINT,
	}) as Promise<IDetailResponse>;
}

export function getPOIIcons(): Promise<IPOIIconResponse> {
	return call('poiicons', [], {
		url: ENDPOINT,
	}) as Promise<IPOIIconResponse>;
}

export interface IPOITypes {
	[key: number]: IPOIIcon;
}

export const POITYPES: IPOITypes = {};

export async function initPOIIcons(): Promise<void> {
	try {
		const response = await getPOIIcons();

		if (response.status === HTTP_STATUS_OK) {
			response.poitype.forEach(poiType => {
				const icon = response.poiicon.find(poiIcon => poiIcon.id === poiType.icon_id);

				if (icon) {
					POITYPES[poiType.id] = icon;
				}
			});
		}
		Object.freeze(POITYPES);
	} catch (ex) {
		//
	}

	return Promise.resolve();
}

export function typeToIcon(typeId: number, poiTypes: IPOITypes = POITYPES): number {
	return typeId in poiTypes ? poiTypes[typeId].id : DEFAULT_ICON;
}

export function getLookup(bounds: [ICoords, ICoords], options: ILookupOptions = {}): Promise<ILookupResponse> {
	const { longitude: lbx, latitude: lby } = bounds[0];
	const { longitude: rtx, latitude: rty } = bounds[bounds.length - 1];
	const params = [
		lbx,
		lby,
		rtx,
		rty,
		{
			zoom: 0,
			mapsetId: 0,
			pixelSize: 1,
			lang: ['cs', 'sk', 'en'],
			...options,
		},
	];

	const hints = {
		'0': 'float',
		'1': 'float',
		'2': 'float',
		'3': 'float',
		'4.pixelSize': 'float',
	};

	return call('lookupbox', params, {
		url: ENDPOINT,
		hints,
	}) as Promise<ILookupResponse>;
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMapset, setCenterZoom, setFit } from '~/features/map/state';
import { setDetail } from '~/features/detail/state';
import { MAPSET_BASE, MAPSET_TURIST, MAPSET_OPHOTO, MAPSET_TRAFFIC, MAPSET_WINTER, MAPSETS } from '~/features/map/constants';
import { ICoords } from '~/features/map/interfaces';

import './styles.less';
import { setDebugLookup } from '~/features/dev/state';
import { debugSelector } from '~/helpers/selectors';

const CR_BOUNDS: Array<ICoords> = [{
	latitude: 50.2605108,
	longitude: 12.0942444,
}, {
	latitude: 51.0579297,
	longitude: 14.3134828,
}, {
	latitude: 49.5452006,
	longitude: 18.8613075,
}, {
	latitude: 48.5513256,
	longitude: 14.3317647,
}];

function onShareClick() {
	navigator.share({
		url: window.location.href,
		text: 'Ethan.cz',
	});
}

export default function DevControls() {
	const dispatch = useDispatch();

	const { debugLookup } = useSelector(debugSelector);

	return (
		<div className="devControls">
			<h2>Změna mapsetu</h2>
			<p>
				<button onClick={() => dispatch(setMapset({ mapset: MAPSET_BASE }))} type="button">{MAPSETS[MAPSET_BASE].title}</button>
				<button onClick={() => dispatch(setMapset({ mapset: MAPSET_TURIST }))} type="button">{MAPSETS[MAPSET_TURIST].title}</button>
				<button onClick={() => dispatch(setMapset({ mapset: MAPSET_OPHOTO }))} type="button">{MAPSETS[MAPSET_OPHOTO].title}</button>
				<button onClick={() => dispatch(setMapset({ mapset: MAPSET_TRAFFIC }))} type="button">{MAPSETS[MAPSET_TRAFFIC].title}</button>
				<button onClick={() => dispatch(setMapset({ mapset: MAPSET_WINTER }))} type="button">{MAPSETS[MAPSET_WINTER].title}</button>
			</p>
			<h2>Detail</h2>
			<p>
				<button onClick={() => dispatch(setDetail({ source: 'addr', id: 9725577 }))} type="button">otevři detail Báňská 334</button>
				<button onClick={() => dispatch(setDetail({ source: 'base', id: 1701792 }))} type="button">otevři detail Hrad Karlštejn</button>
			</p>
			<h2>Test dalších funkčností dosud bez UI</h2>
			<p>
				<button onClick={() => dispatch(setFit({ fit: CR_BOUNDS }))} type="button">best fit ČR</button>
				<button onClick={() => dispatch(setCenterZoom({ coords: { latitude: 50.0710792, longitude: 14.40094 }, zoom: 17 }))} type="button">střed mapy Seznam Praha</button>
				<button onClick={() => dispatch(setCenterZoom({ coords: { latitude: 49.1718178, longitude: 16.5990161 }, zoom: 17 }))} type="button">střed mapy Seznam Brno</button>
			</p>
			<h2>Debug</h2>
			<p><label><input type="checkbox" onChange={() => dispatch(setDebugLookup(!debugLookup))} checked={debugLookup} /> lookup</label></p>
			{'share' in navigator
				? <>
					<h2>Sdílení</h2>
					<p>
						<button type="button" onClick={onShareClick}>Sdílet</button>
					</p>
				</>
				: null}
		</div>
	);
}

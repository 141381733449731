import React, { ButtonHTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisibility } from '~/features/common/state';
import { sidebarVisibleSelector } from '~/helpers/selectors';

import './styles.less';

export default function SidebarToggle(props: ButtonHTMLAttributes<HTMLButtonElement>) {
	const dispatch = useDispatch();
	const sidebarVisible: boolean = useSelector(sidebarVisibleSelector);

	function onClick() {
		dispatch(setSidebarVisibility(!sidebarVisible));
	}

	return <button className="sidebarToggle" type="button" {...props} onClick={onClick}>⇄</button>;
}

import { IMapyState } from '~/interfaces';

// selector vlastnosti ze store
export function activeFeatureSelector(state: IMapyState) {
	return {
		activeFeature: state.common.activeFeature,
	};
}

export function detailSelector(state: IMapyState) {
	return {
		gallery: state.detail?.data?.gallery,
		loading: state.detail.loading,
		title: state.detail.data?.title,
		description: state.detail.data?.desc?.text || state.detail.data?.description || '',
	};
}

// selector vlastnosti ze store
export function maplibreSelector(state: IMapyState) {
	return {
		longitude: state.map.coords.longitude,
		latitude: state.map.coords.latitude,
		zoom: state.map.zoom,
		fit: state.map.fit,
	};
}

export function mapsetSelector(state: IMapyState) {
	return {
		mapset: state.map.mapset,
		latitude: state.map.coords.latitude,
		longitude: state.map.coords.longitude,
		zoom: state.map.zoom,
	};
}

export function detailSourceIdSelector(state: IMapyState) {
	return {
		source: state.detail.source,
		id: state.detail.id,
	};
}

export function tilesSelector(state: IMapyState) {
	return state.map.mapset;
}

export function sidebarVisibleSelector(state: IMapyState) {
	return state.common.sidebarVisible;
}

export function debugSelector(state: IMapyState) {
	return {
		...state.dev,
	};
}

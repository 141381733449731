import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '~/helpers/classnames';
import { sidebarVisibleSelector } from '~/helpers/selectors';

import './styles.less';

export default function Sidebar(props: HTMLAttributes<HTMLElement>) {
	const sidebarVisible = useSelector(sidebarVisibleSelector);
	const classes = classNames([
		'sidebar',
		{ 'sidebar--hidden': !sidebarVisible },
		props.className || '',
	]);

	return <main {...props} className={classes}></main>;
}

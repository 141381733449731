import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import commonReducer, { commonMiddleware } from '~/features/common/state';
import mapReducer from '~/features/map/state';
import detailReducer, { detailMiddleware } from '~/features/detail/state';
import devReducer from '~/features/dev/state';
import searchReducer from '~/features/search/state';
import { historyMiddleware, historyInit, withHistoryReducer } from '~/services/history';

// nahodime Redux store
export const store = configureStore({
	reducer: withHistoryReducer(combineReducers({
		common: commonReducer,
		map: mapReducer,
		detail: detailReducer,
		search: searchReducer,
		dev: devReducer,
	})),
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
		historyMiddleware,
		detailMiddleware,
		commonMiddleware,
	]),
	// TODO: nacist stav z URL, dotahat co je v nem treba, aby se urcily spravne souradnice a podobne a pouzit tady
	//preloadedState
});

// navazeme zmeny historie na store
historyInit(store);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { initialState } from '~/services/history';
import { IDetailState, IDetailRequestPayload, IDetailDataPayload, IDetailErrorPayload } from './interfaces';
import { getDetail } from '~/services/poiagg';
import { EMPTY_DETAIL } from './constants';
import { IMapyState } from '~/interfaces';

const mapSlice = createSlice({
	name: 'detail',
	initialState: initialState.detail as IDetailState,
	reducers: {
		setDetail: (state: IDetailState, action: PayloadAction<IDetailRequestPayload>) => {
			const { source = EMPTY_DETAIL.source, id = EMPTY_DETAIL.id } = action.payload;

			// detail se od posledne zmenil, vynulujeme tedy data toho predchoziho
			if (state.source !== source || state.id !== id) {
				state.data = null;
				state.source = source;
				state.id = id;
			}

			// chceme, aby se detail nacetl - aplikace uz vi, ze ma ukazat loader
			state.loading = source !== EMPTY_DETAIL.source && id !== EMPTY_DETAIL.id;
		},
		setDetailData: (state: IDetailState, action: PayloadAction<IDetailDataPayload>) => {
			const { source, id } = state;

			// zmenime data jen, pokud se v dobe cekani na ne nezmenil source a id
			if (source === action.payload.data.source && id === action.payload.data.id) {
				state.loading = false;
				state.data = action.payload.data;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setDetailError: (state: IDetailState, action: PayloadAction<IDetailErrorPayload>) => {
			state.loading = false;
			state.error = true;
		},
	},
});

// dle "duck" metodiky jsou akce pojmenovane exporty a reducer default export
export const { setDetail, setDetailData, setDetailError } = mapSlice.actions;
export default mapSlice.reducer;

export const detailMiddleware: Middleware = store => next => action => {
	if (action.type !== mapSlice.actions.setDetail.type && action.type !== 'historyPopState') {
		return next(action);
	}

	const result = next(action);
	const state: IMapyState = store.getState();
	const { source, id, loading, data } = state.detail;

	if (loading && data === null) {
		getDetail(source, id, {
			fetchPhoto: true,
			ratios: [
				'default',
				'1x1',
				'16x9',
			],
			lang: [
				'cs',
				'sk',
				'en',
			],
		})
			.then(response => store.dispatch(setDetailData({ data: response.poi } as IDetailDataPayload)))
			.catch(error => store.dispatch(setDetailError(error.toString())));
	}

	return result;
};

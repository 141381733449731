import { IDetailState, IDetailRequestPayload } from './interfaces';

export const EMPTY_DETAIL: IDetailRequestPayload = {
	source: '',
	id: 0,
};

export const DEFAULT_DETAIL_STATE: IDetailState = {
	...EMPTY_DETAIL,
	loading: false,
	error: false,
	data: null,
};

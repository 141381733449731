import { DEFAULT_MAP_STATE } from '~/features/map/constants';
import { DEFAULT_DETAIL_STATE } from '~/features/detail/constants';
import { DEFAULT_COMMON_STATE } from '~/features/common/constants';
import { DEFAULT_SEARCH_STATE } from '~/features/search/constants';
import { DEFAULT_DEV_STATE } from '~/features/dev/constants';
import { IMapyState } from '~/interfaces';
import { PayloadAction, EnhancedStore } from '@reduxjs/toolkit';
import { Reducer, Middleware } from 'redux';
import { Feature } from '~/features/common/interfaces';

export interface IHistoryPayload {
	replaceUrl?: boolean;
}

export interface IHistoryPopStatePayload {
	url: string;
}

// TODO: naucit se vic nez jen detail
function urlParamsToActiveFeature(params: URLSearchParams) {
	if (params.has('source') && params.has('id')) {
		return Feature.Detail;
	}

	return Feature.Search;
}

// TODO: rozdelit tuhle funkci na dilci funkce, ktere budou patrit jednotlivym castem store, ktere se staraji o danou cast map (pokud nebude lepsi mit to sice v dlouhe, ale zase prehledne funkci na jednom miste)
// tohle bude jedna z nejslozitejsich funkci tady - dostane predchozi stav a novou URL a vrati nakombinovany stav
export function urlToState(prevState: IMapyState, url: string): IMapyState {
	const halves = url.split('?');
	const mapsetGroups = halves[0].match(/\/([a-z\-0-9]+)$/u);
	const params = new URLSearchParams(halves.length > 1 ? halves[1] : '');

	// nakombeni puvodniho store.common a toho, co prijde z URL
	const common: typeof prevState.common = {
		sidebarVisible: !(params.has('l') && parseInt(params.get('l') as string, 10) === 0),
		activeFeature: urlParamsToActiveFeature(params),
	};

	// nakombeni puvodniho store.map a toho, co prijde z URL
	const map: typeof prevState.map = {
		...prevState.map,
		...mapsetGroups && mapsetGroups.length ? { mapset: mapsetGroups[1] } : {},
		...params.has('x') && params.has('y')
			? {
				coords: {
					longitude: parseFloat(params.get('x') as string),
					latitude: parseFloat(params.get('y') as string),
				},
			}
			: {},
		...params.has('z') ? { zoom: parseFloat(params.get('z') as string) - 1 } : {},
	};

	// nakombeni puvodniho store.detail a toho, co prijde z URL
	const source = params.get('source') || DEFAULT_DETAIL_STATE.source;
	const id = params.has('id') ? source === 'coor' ? params.get('id') as string : parseInt(params.get('id') as string, 10) : DEFAULT_DETAIL_STATE.id;
	const newDetail = prevState.detail.source !== source && prevState.detail.id !== id;
	const detail: typeof prevState.detail = {
		...prevState.detail,
		...params.has('source') && params.has('id')
			? {
				source,
				id,
				loading: source !== '' && id !== 0 && newDetail,
				error: false,
				data: newDetail ? null : prevState.detail.data,
			}
			: DEFAULT_DETAIL_STATE,
	};

	// nakombeni puvodniho store.search a toho, co prijde z URL
	const search: typeof prevState.search = {
		phrase: params.has('q') ? params.get('q') as string : '',
	};

	const dev: typeof prevState.dev = {
		debugLookup: prevState.dev.debugLookup,
	};

	return {
		common,
		map,
		detail,
		search,
		dev,
	};
}

export function stateToUrl(state: IMapyState): string {
	// mapset
	const mapset = state.map.mapset;
	// ostatni parametry do URL
	const params = new URLSearchParams();

	// souradnice a zoom
	params.set('x', state.map.coords.longitude.toString());
	params.set('y', state.map.coords.latitude.toString());
	params.set('z', (state.map.zoom + 1).toString());

	// info o detailu, pokud nejaky je
	if (state.detail.source && state.detail.id) {
		params.set('source', state.detail.source);
		params.set('id', state.detail.id.toString());
	}

	// stav sidebaru
	if (!state.common.sidebarVisible) {
		params.set('l', '0');
	}

	// stav vyhledavani
	if (state.search.phrase !== '') {
		params.set('q', state.search.phrase);
	}

	return `${location.origin}/${mapset}?${params.toString()}`;
}

// uplne prazdny uvodni stav, ktery zahy nahradi stav z volani historyInit
export const initialState: IMapyState = {
	common: DEFAULT_COMMON_STATE,
	map: DEFAULT_MAP_STATE,
	detail: DEFAULT_DETAIL_STATE,
	search: DEFAULT_SEARCH_STATE,
	dev: DEFAULT_DEV_STATE,
};

// s kazdou zmenou stavu se pregeneruje URL, pokud vyjde jina
export const historyMiddleware: Middleware = store => next => action => {
	const result = next(action);
	const state = store.getState();
	const url = stateToUrl(state);

	if (action.type !== 'historyPopState' && url !== location.href) {
		if (action.replaceUrl) {
			history.replaceState(undefined, '', url);
		} else {
			history.pushState(undefined, '', url);
		}
	}

	return result;
};

export function historyInit(store: EnhancedStore<any, any>) {
	history.replaceState(undefined, '', stateToUrl(urlToState(initialState, location.href)));

	function onPopState() {
		store.dispatch({
			type: 'historyPopState',
			payload: {
				url: location.href,
			},
		} as PayloadAction<IHistoryPopStatePayload>);
	}

	window.addEventListener('popstate', onPopState);

	onPopState();
}

export function withHistoryReducer(reducer: Reducer) {
	return function historyReducer(state: IMapyState = initialState, action: PayloadAction<IHistoryPopStatePayload>) {
		if (action.type === 'historyPopState') {
			const newState = {
				...state,
				...urlToState(state, action.payload.url),
			};

			return newState;
		}

		return reducer(state, action);
	};
}

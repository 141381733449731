import React, { HTMLAttributes } from 'react';
import { classNames } from '~/helpers/classnames';
import { EMPTY_DETAIL } from '~/features/detail/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setDetail } from '~/features/detail/state';
import { detailSelector } from '~/helpers/selectors';

import './styles.less';
import poiBestPlaceholder from '~/img/poibest-placeholder.svg';

export default function Detail(props: HTMLAttributes<HTMLDivElement>) {
	const classes = classNames([
		'detail',
		props.className || '',
	]);
	const dispatch = useDispatch();
	const { gallery, loading, title, description } = useSelector(detailSelector);
	const best = gallery && gallery.length && gallery[0].media.length && gallery[0].media[0].urls['16x9'] ? gallery[0].media[0].urls['16x9'] : '';

	function onCloseDetail() {
		dispatch(setDetail({ source: EMPTY_DETAIL.source, id: EMPTY_DETAIL.id }));
	}

	return <section {...props} className={classes}>
		{loading
			? null
			: <>
				<img src={best ? best.replace('{width}', '400').replace('{height}', '225') : poiBestPlaceholder} className="detail__poster" />
				<div className="detail__toolbar">
					<button type="button" onClick={onCloseDetail} className="detail__closeButton">
						<svg width="10" height="16" viewBox="0 0 10 16" className="icon icon-arrow-left">
							<path d="M8.7,2.7C9,2.4,9,1.9,8.7,1.6c-0.3-0.3-0.8-0.3-1.1,0L1.1,8.2l6.5,6.5c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1L3.2,8.2 L8.7,2.7z"></path>
						</svg>
					</button>
				</div>
				<div className="detail__content">
					<h1 className="detail__title">{title}</h1>
					<article>
						<div className="detail__description" dangerouslySetInnerHTML={{ __html: description }}></div>
					</article>
				</div>
			</>}
	</section>;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '~/services/history';
import { IDevState } from './interfaces';

const mapSlice = createSlice({
	name: 'dev',
	initialState: initialState.dev as IDevState,
	reducers: {
		setDebugLookup: (state: IDevState, action: PayloadAction<boolean>) => {
			state.debugLookup = action.payload;
		},
	},
});

// dle "duck" metodiky jsou akce pojmenovane exporty a reducer default export
export const { setDebugLookup } = mapSlice.actions;
export default mapSlice.reducer;

import Request from './request';

export function call(method: string, args: any[] = [], options = {}) {
	return new Request(method, args, options).getPromise();
}

export function multicall(methods: string[], args: any[] = [], options: any) {
	const data = methods.map((methodName, index) => {
		const params = args[index] || [];

		return { methodName, params };
	});

	return call('system.multicall', [data], options);
}
